import React from 'react'
import './AdminPanel.css';
import AdminNav from './AdminNav';
import { Link, useNavigate } from 'react-router-dom';

const AdminPanel = () => {
    const navigate = useNavigate();
    return (
        <div className='Admin_container'>
            <AdminNav />
            <hr className='nav_hr' />
            <div className="Admin_panelContent">
                <div className="kamana_content_manage_div">
                <div className="card_buttons_div">
                        <div className="card_title_div">
                            <p>Contact</p>
                        </div>
                        <div className='card_buttons'>
                            <div className="button_cards" onClick={() => { navigate('/backend/admin/ContactData') }}>
                                <Link className='card_item'>See Inquiries</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="card_buttons_div">
                        <div className="card_title_div">
                            <p>Manage Blog's</p>
                        </div>
                        <div className='card_buttons'>
                            <div className="button_cards" onClick={() => { navigate('/backend/admin/AddBlog') }}>
                                <Link className='card_item'>ADD Blog</Link>
                            </div>
                            <div className="button_cards" onClick={() => { navigate('/backend/admin/EditBlog') }}>
                                <Link className='card_item'>Edit Blog</Link>
                            </div>
                            <div className="button_cards" onClick={() => { navigate('/backend/admin/DeleteBlog') }}>
                                <Link className='card_item'>Delete Blog</Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className="card_buttons_div">
                        <div className="card_title_div">
                            <p>Manage Product's</p>
                        </div>
                        <div className='card_buttons'>
                            <div className="button_cards" onClick={() => { navigate('/backend/admin/AddProducts') }}>
                                <Link className='card_item'>ADD New Product</Link>
                            </div>
                            <div className="button_cards">
                                <Link className='card_item'>Edit Product</Link>
                            </div>
                            <div className="button_cards">
                                <Link className='card_item'>Delete Product</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AdminPanel
