import React, {useEffect} from 'react'
import AluminiumScrap from '../../product_images/AluminiumScrap.jpg'
import AluminiumAutomotive from '../../product_images/AluminiumAutomotive.jpg'
import AluminiumConstruction from '../../product_images/AluminiumConstruction.jpg'
import AluminiumPakage from '../../product_images/AluminiumPackage.jpg'
import AluminiumElectronics from '../../product_images/AluminiumElectronics.jpg'
import AluminiumAerospace from '../../product_images/AluminiumAerospace.jpg'

const Aluminium = () => {
    const ScrollToTopOnVisit = () => {
        useEffect(() => {
            window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        }, []); // Empty dependency array ensures this effect runs only once on mount
    
        return null; // This component doesn't render anything
    };
    ScrollToTopOnVisit()
    return (
        <>
            <div className="About_container">
                <div className="Headline_tag">
                    <p className='About_kamana'>Aluminium Scrap</p>
                </div>

                <div className="upper_part_product">
                    <div className="about_image_div" data-aos="fade-right"> 
                        <img className='product_img' src={AluminiumScrap} alt="" />
                    </div>
                    <div className="tagline_div product_detail_div" data-aos="fade-left">
                        <p className='tagline'>Aluminium scrap refers to recyclable aluminium materials discarded from various sources such as manufacturing processes and consumer products, including old vehicles, machinery, and household items, which can be processed and reused in new aluminium production, reducing environmental impact.</p>
                    </div>
                </div>

                <div className="lower_aboutDescription" style={{marginBottom: '-11rem'}}>

                    <div className="packaging_div"  style={{marginBottom: '1rem'}}>
                        <div className="feild_title_div">
                            <p className='composition_title'>Types</p>
                        </div>

                        <div className="packaging_cards_div" data-aos="zoom-in-up">

                            <div className="packaging_card">
                                <div className="packaging_card_title">Taint/Tabor (T/T) Scrap</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Taint/Tabor is a commonly traded grade of aluminum scrap and is primarily derived from clean, uncoated aluminum sheets and profiles. This type of scrap is free from iron, plastic, or other contaminants and is often used for recycling into new aluminum products.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Aluminum Extrusion Scrap (6063)</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Aluminum extrusion scrap includes materials such as aluminum window frames, door frames, and other aluminum extrusions. This type of scrap is sought after for its high aluminum content and is widely used in the production of new extruded aluminum products.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Aluminum UBC (Used Beverage Cans) Scrap</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">UBC scrap mainly consists of discarded aluminum beverage cans. It is a highly recyclable material and is processed to produce new aluminum cans or other aluminum products, contributing to sustainable packaging solutions.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Aluminum Wheel Scrap</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content"> Aluminum wheel scrap is derived from discarded aluminum wheels commonly found in automotive applications. It generally contains high-quality aluminum and is sought after for recycling into new aluminum alloys.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Cast Aluminum Scrap</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Cast aluminum scrap is obtained from items such as engine components, household items, and decorative pieces made from aluminum casting. This type of scrap can be recycled and used in the manufacturing of various aluminum products.</div>
                            </div>

                        </div>

                    </div>

                    <div className="Application_div" data-aos="zoom-in-up">
                        <div className="feild_title_div">
                            <p className="composition_title">Applications</p>
                        </div>

                        <div className="application_list_div">
                            <div className="applications">
                                <img src={AluminiumAutomotive} className='application_image' alt="" />
                                <p className='application_title'>Automotive</p>
                                <p className='application_detail'>Recycled into lightweight parts to improve fuel efficiency.</p>
                            </div>
                            <div className="applications">
                                <img src={AluminiumConstruction} className='application_image' alt="" />
                                <p className='application_title'>Construction</p>
                                <p className='application_detail'>Used for structural components and building materials.</p>
                            </div>
                            <div className="applications">
                                <img src={AluminiumPakage} className='application_image' alt="" />
                                <p className='application_title'>Packaging</p>
                                <p className='application_detail'>Converted into cans and foil, reducing waste.</p>
                            </div>
                            <div className="applications">
                                <img src={AluminiumElectronics} className='application_image' alt="" />
                                <p className='application_title'>Electronics</p>
                                <p className='application_detail'>Reused in components for devices and appliances.</p>
                            </div>
                            <div className="applications">
                                <img src={AluminiumAerospace} className='application_image' alt="" />
                                <p className='application_title'>Aerospace</p>
                                <p className='application_detail'>Formed into aircraft parts for weight reduction and strength.</p>
                            </div>
                        </div>

                        <div className="packaging_div" data-aos="zoom-in-up">
                            <div className="feild_title_div">
                                <p className='composition_title'>Packaging</p>
                            </div>

                            <div className="packaging_cards_div">

                                <div className="packaging_card">
                                    <div className="packaging_card_title">25KG Bags</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">For customers requiring smaller quantities of bentonite, Kamana Traders provides 25 kg bags. The bentonite is packed in high-quality polypropylene bags for added durability and protection during transportation.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Bulk Bags</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content"> Kamana Traders offers bulk bags for clients requiring large quantities of bentonite. The bulk bags can hold up to 1 ton of bentonite and can be loaded into containers for easy shipment.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Eco-Friendly Packaging</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">Kamana Traders is committed to sustainability, and we offer eco-friendly packaging options for bentonite. Customers who prefer sustainable packaging can request biodegradable or recyclable packaging materials.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Customized Packaging</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">At Kamana Traders, we understand that different clients may have their unique packaging requirements. We offer customized packaging solutions that meet our clients' specific needs and preferences.</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aluminium
