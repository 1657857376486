import React from 'react'
import AboutImage from './AboutImage.jpg'
import './About.css'

const About = () => {
  return (
    <>
      <div className="About_container">
        <div className="Headline_tag">
          <p className='About_kamana'>About Kamana</p>
        </div>

        <div className="upper_part_img_tagline">
          <div className="tagline_div">
            <p className='how_it_started'>How it Started</p>
            <div>
              <p className='tagline'>From Scrap to Substance,</p>
              <p className='tagline'>Delivering Excellence in Every Shipment.</p>
            </div>
          </div>
          <div className="about_image_div">
            <img className='About_img' src={AboutImage} alt="About_img" loading='lazy' />
          </div>
        </div>

        <div className="lower_aboutDescription">
          <div className="Section_title_div">
            <p className="section_title">Discover the Kamana Journey</p>
          </div>
          <div className="Description_div">
            <div className="points">
              <p className="point_title">Essential Material Specialists :</p>
              <p>Specializing in sourcing and distributing premium materials like copper scrap, aluminium scrap, china clay, and bentonite, Kamana Traders bridges the gap between raw resources and thriving industries.</p>
            </div>
            <hr className="divider" />
            <div className="points">
              <p className="point_title">Charting Our Journey :</p>
              <p>Our journey began with a commitment to excellence and facilitating seamless trade connections worldwide. We navigate the complexities of global trade to deliver unparalleled quality and reliability.</p>
            </div>
            <hr className="divider" />
            <div className="points">
              <p className="point_title">Our Differentiator :</p>
              <p>What sets us apart is our dedication to our customers' success. We're a catalyst for growth and innovation, serving as a steadfast partner on the path to prosperity.</p>
            </div>
            <hr className="divider" />
            <div className="points">
              <p className="point_title">Commitment to Sustainability :</p>
              <p>Beyond quality and service, sustainability is at the heart of everything we do. We practice responsible sourcing and environmentally conscious operations to safeguard resources for future generations.</p>
            </div>
            <hr className="divider" />
            <div className="points">
              <p className="point_title">Guided by Values :</p>
              <p>Integrity, collaboration, and customer-centricity guide our expansion and impact. At Kamana Traders, we don't just deliver materials; we deliver value, reliability, and peace of mind.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
