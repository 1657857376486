import React, { useState, useEffect } from 'react';
import './ToTop.css';

const ToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const threshold = 200; // You can adjust this value based on when you want the button to appear
    setIsVisible(scrollTop > threshold);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div className="toTop_div" onClick={scrollToTop}>
          <p>&#x2191;</p>
        </div>
      )}
    </>
  );
};

export default ToTop;
