import React, { useState, useEffect } from 'react';
import './Blog.css'
import FullBlog from './FullBlog/FullBlog';
import BlogLoader from './BlogLoader/BlogLoader'
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [fullBlog, setFullBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true)
      try {
        const response = await fetch('https://kamana-website-server.vercel.app/blog/fetchBlogs');

        const data = await response.json();
        if (data.success) {
          setBlogs(data.AllBlogs.reverse());
        } else {
          console.log('something went wrong')
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false)
      }
    };

    fetchBlogs();
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '....';
  };

  const formatDescription = (description) => {
    // Replace newline characters with HTML line breaks
    let formattedDescription = description.replace(/\n/g, '<br>');

    // Wrap bold text with <strong> tags
    formattedDescription = formattedDescription.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

    // Add support for other HTML tags
    formattedDescription = formattedDescription.replace(/__(.*?)__/g, '<u>$1</u>'); // Underline
    formattedDescription = formattedDescription.replace(/~(.*?)~/g, '<em>$1</em>'); // Italics
    formattedDescription = formattedDescription.replace(/<(\/?h[1-6])>/g, '<$1>'); // Headings
    formattedDescription = formattedDescription.replace(/<(\/?p)>/g, '<$1>'); // Paragraphs
    formattedDescription = formattedDescription.replace(/<(\/?ul|\/?ol|\/?li)>/g, '<$1>'); // Lists and list items
    formattedDescription = formattedDescription.replace(/<(\/?blockquote)>/g, '<$1>'); // Blockquotes
    formattedDescription = formattedDescription.replace(/<(\/?cite)>/g, '<$1>'); // Cite
    formattedDescription = formattedDescription.replace(/<(\/?code)>/g, '<$1>'); // Code
    formattedDescription = formattedDescription.replace(/<(\/?pre)>/g, '<$1>'); // Preformatted text
    formattedDescription = formattedDescription.replace(/<(\/?a)>/g, '<$1>'); // Hyperlinks
    formattedDescription = formattedDescription.replace(/<(\/?img)>/g, '<$1>'); // Images
    formattedDescription = formattedDescription.replace(/<(\/?figure)>/g, '<$1>'); // Figures
    formattedDescription = formattedDescription.replace(/<(\/?figcaption)>/g, '<$1>'); // Figcaption
    formattedDescription = formattedDescription.replace(/<(\/?div|\/?span)>/g, '<$1>'); // Div and span
    formattedDescription = formattedDescription.replace(/<(\/?sub|\/?sup)>/g, '<$1>'); // Subscript and superscript
    formattedDescription = formattedDescription.replace(/<(\/?abbr|\/?acronym)>/g, '<$1>'); // Abbreviation and acronym
    formattedDescription = formattedDescription.replace(/<(\/?del|\/?ins)>/g, '<$1>'); // Deleted and inserted text
    formattedDescription = formattedDescription.replace(/<(\/?hr)>/g, '<$1>'); // Horizontal rule

    // You can add more HTML tag replacements as needed

    return formattedDescription;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Create an array of month names
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Return formatted date string
    return `${day} ${monthNames[monthIndex]} ${year}`;
  };

  return (
    <>
      <div className="About_container">
        <div className="Headline_tag">
          <p className='About_kamana'>Kamana Updates</p>
        </div>

        <div className="blog_collection">
          {loading && <><BlogLoader /> <BlogLoader /></>}
          {blogs.map(blog => (
            <div className="blog_item" key={blog._id} >
              {blog.image && (
                <div className="blog_image_div" onClick={() => setFullBlog(blog)}>
                  <img src={blog.image} alt="" />
                </div>
              )}
              <div className="Blog_text_div">
                <div className="blog_title_div" onClick={() => setFullBlog(blog)}>
                  <p>{blog.title}</p>
                </div>
                <div className="blog_description_div" onClick={() => setFullBlog(blog)}>
                  <div dangerouslySetInnerHTML={{ __html: formatDescription(truncateText(blog.description, 200)) + '<span>Read more</span>' }} />
                </div>

                <div className="blog_date_like_comment_div">
                  <div className="kamanaTradersMark_div">
                    <div className="kamana_logo_div" onClick={() => navigate('/')}>
                      <img src="./profile.png" alt="" />
                      <p>Kamana Traders</p>
                    </div>
                    <div class="dot-container">
                      <div class="dot"></div>
                    </div>
                    <div className="date_div">
                      <p>{formatDate(blog.date)}</p>
                    </div>
                  </div>
                  {/* <div className="Like_div">
                    <Like />
                  </div> */}
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>

      {fullBlog && (
        <FullBlog fullBlog={fullBlog} setFullBlog={setFullBlog} />
      )}
    </>
  )
}

export default Blog
