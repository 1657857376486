import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AdminPanel from './AdminPanel';
import Login from './Login/Login';
import AddBlog from './AddBlog/AddBlog';
import DeleteBlog from './DeleteBlog/DeleteBlog';
import EditBlog from './EditBlog/EditBlog';
import ContactData from './ContactData/ContactData';

const AdminPanelRoutes = () => {
  const isAuthenticated = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page if not authenticated
    if (!isAuthenticated) {
      navigate('/backend/login'); 
    }
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/admin/*" element={<AdminPanel />} />
      <Route path="/admin/AddBlog" element={<AddBlog />} />
      <Route path="/admin/DeleteBlog" element={<DeleteBlog />} />
      <Route path="/admin/EditBlog" element={<EditBlog />} />
      <Route path="/admin/ContactData" element={<ContactData />} />
    </Routes>
  );
};

export default AdminPanelRoutes;
