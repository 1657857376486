import React, {useEffect} from 'react'
import chinaClay from '../../product_images/chinaClay.jpg'
import './ChinaClay.css'
import ChinaClay1 from '../../product_images/ChinaClay1.png'
import ChinaClayPaper from '../../product_images/ChinaClayPaper.jpg'
import ChinaClayCeramics from '../../product_images/ChinaClayCeramics.jpg'
import ChinaClayPaint from '../../product_images/ChinaClayPaint.jpg'
import ChinaClayRubber from '../../product_images/ChinaClayRubber.jpg'
import ChinaClayCosmetics from '../../product_images/ChinaClayCosmetics.jpg'
import ChinaClayPharmaceuticals from '../../product_images/ChinaClayPharmaceuticals.jpg'

const ChinaClay = () => {
    const ScrollToTopOnVisit = () => {
        useEffect(() => {
            window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        }, []); // Empty dependency array ensures this effect runs only once on mount
    
        return null; // This component doesn't render anything
    };
    ScrollToTopOnVisit()
    return (
        <>
            <div className="About_container">
                <div className="Headline_tag">
                    <p className='About_kamana'>China Clay</p>
                </div>

                <div className="upper_part_product">
                    <div className="about_image_div" data-aos="fade-right">
                        <img className='product_img' src={chinaClay} alt="" />
                    </div>
                    <div className="tagline_div product_detail_div" data-aos="fade-left">
                        <p className='tagline'>China clay, or kaolin, a natural mineral widely used across industries, is sourced from superior deposits to guarantee purity. Its fine particle size and plasticity make it ideal for ceramics, paints, rubber, and cosmetics, offering exceptional whiteness and stability for diverse industrial needs.</p>
                    </div>
                </div>

                <div className="lower_aboutDescription">
                    <div className="composition_div">
                        <div className="feild_title_div">
                            <p className="composition_title">Composition</p>
                        </div>
                        <div className="feild_details_div" data-aos="zoom-in-up">
                            <div className="feild_detail_data">
                                <p>China clay, also known as kaolin, is primarily composed of the mineral kaolinite. It features fine particle size and high purity, with additional trace elements like quartz, mica, and feldspar. Its chemical formula is Al2Si2O5(OH)4, making it ideal for various industrial applications due to its plasticity, whiteness, and stability.</p>
                            </div>

                            <div className="feild_detail_image_div">
                                <img className='product_img' src={ChinaClay1} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="Application_div">
                        <div className="feild_title_div">
                            <p className="composition_title">Applications</p>
                        </div>

                        <div className="application_list_div" data-aos="zoom-in-up">
                            <div className="applications">
                                <img src={ChinaClayPaper} className='application_image' alt="" />
                                <p className='application_title'>Papermaking</p>
                                <p className='application_detail'>Enhances paper brightness, smoothness, and printability.</p>
                            </div>
                            <div className="applications">
                                <img src={ChinaClayCeramics} className='application_image' alt="" />
                                <p className='application_title'>Ceramics</p>
                                <p className='application_detail'>Used in the production of porcelain and pottery.</p>
                            </div>
                            <div className="applications">
                                <img src={ChinaClayPaint} className='application_image' alt="" />
                                <p className='application_title'>Paints</p>
                                <p className='application_detail'>Acts as an extender and provides opacity and gloss.</p>
                            </div>
                            <div className="applications">
                                <img src={ChinaClayRubber} className='application_image' alt="" />
                                <p className='application_title'>Rubber</p>
                                <p className='application_detail'>Improves durability and elasticity.</p>
                            </div>
                            <div className="applications">
                                <img src={ChinaClayCosmetics} className='application_image' alt="" />
                                <p className='application_title'>Cosmetics</p>
                                <p className='application_detail'>Utilized in facial masks and powders for its absorbent properties.</p>
                            </div>
                            <div className="applications">
                                <img src={ChinaClayPharmaceuticals} className='application_image' alt="" />
                                <p className='application_title'>Pharmaceuticals</p>
                                <p className='application_detail'>Serves as an excipient and binding agent in tablets.</p>
                            </div>
                        </div>

                        <div className="packaging_div">
                            <div className="feild_title_div">
                                <p className='composition_title'>Packaging</p>
                            </div>

                            <div className="packaging_cards_div" data-aos="zoom-in-up">

                                <div className="packaging_card">
                                    <div className="packaging_card_title">50KG Bags</div>
                                    <hr className="packaging_card_divider"/>
                                    <div className="packaging_card_content">Kamana Traders offers 50 kg bags for customers requiring smaller quantities of china clay. The china clay is packed in high-quality polypropylene bags for added durability and protection during transportation.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Bulk Bags</div>
                                    <hr className="packaging_card_divider"/>
                                    <div className="packaging_card_content"> For clients requiring large quantities of china clay, Kamana Traders provides bulk bags. The bulk bags can hold up to 1 tonne of china clay and are loaded into containers for easy shipment.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Eco-Friendly Packaging</div>
                                    <hr className="packaging_card_divider"/>
                                    <div className="packaging_card_content">We offer eco-friendly packaging options for china clay, including using biodegradable or recyclable packaging materials, to support our clients' sustainability initiatives.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Customized Packaging</div>
                                    <hr className="packaging_card_divider"/>
                                    <div className="packaging_card_content">Kamana Traders also offers customized packaging solutions for china clay to meet specific client requirements and preferences.</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChinaClay
