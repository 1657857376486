import React, { useState } from 'react'
import './AddBlog.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/Loader';
import AdminNav from '../AdminNav';

const AddBlog = () => {
    const [imgPreview, setImagePreview] = useState('');
    const [BlogData, setBlogData] = useState({ title: '', description: '', image: '' })
    const [loading, setLoading] = useState(false)

    const onChange = (e) => {
        setBlogData({ ...BlogData, [e.target.name]: e.target.value });
    }

    const convertToBase64 = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
    
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 600;
                let width = img.width;
                let height = img.height;
    
                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
    
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
    
                // Convert canvas content to Data URL (Base64-encoded string)
                const dataUrl = canvas.toDataURL('image/jpeg', 0.7); // Adjust JPEG quality as needed
    
                setBlogData({ ...BlogData, image: dataUrl });
                setImagePreview(dataUrl);
            };
        };
    
        reader.readAsDataURL(file);
    };


    const saveBlog = async (e) => {
        console.log(BlogData)
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('https://kamana-website-server.vercel.app/Blog/storeBlog', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(BlogData),
            });
            const json = await response.json();
            if (json.success) {
                toast.success('Blog Stored Successfull..', {
                    position: "bottom-right",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            } else {
                console.log(json.errors)
                toast.error('Invalid blog data', {
                    position: "bottom-right",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            toast.error('Blog Fail', {
                position: "bottom-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.log('Blog fail', error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <div className="AddBlog_container">
                <AdminNav />
                <hr className='nav_hr' />
                <ToastContainer />
                {loading && (<Loader />)}
                <form className='AddBlog_form' onSubmit={saveBlog}>
                    <div className="blog_form_title_div">
                        <p>Add Blog</p>
                    </div>
                    <div className="login_inputBox blog_input_box">
                        <input type="text" name='title' id='blog_title' onChange={onChange} required />
                        <span>Blog title</span>
                    </div>
                    <div className="blog_textarea">
                        <textarea name="description" id="description" cols="30" rows="10" placeholder='Blog Description' onChange={onChange} required></textarea>
                    </div>
                    <div className="blog_image_upload_div">
                        <label className="custum-file-upload" htmlFor="file">
                            {imgPreview ? (<img className='imgPreview' src={imgPreview} alt="imgPreview" />) : (<><div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24"><g strokeWidth="0" id="SVGRepo_bgCarrier"></g><g strokeLinejoin="round" strokeLinecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" clipRule="evenodd" fillRule="evenodd"></path> </g></svg>
                            </div>
                                <div className="text">
                                    <span>Click to upload image</span>
                                </div></>)}
                            <input accept='image/*' onChange={convertToBase64} type="file" id="file" />
                        </label>
                    </div>
                    <div className="add_button">
                        <button className="login_enter" type="submit">Add</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddBlog
