import React, { useContext } from 'react'
import './Footer.css'
import iso from './iso.png'
import logo from '../Navbar/logo.png'
import logo_dark from '../Navbar/logo_dark.png'
import { ThemeContext } from '../Theme/ThemeContext'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const { theme } = useContext(ThemeContext);

    const handleEmailClick = () => {
        window.location.href = 'mailto:export@kamanatraders.com';
    };

    const handlePhoneClick = () => {
        window.location.href = 'tel:+917574059777';
    };

    const goToISO = () => {
        window.open(
          'https://drive.google.com/file/d/14Usl6AJaJZpabsjfvgbskh8Cs8x7ibJD/view?usp=sharing',
          '_blank'
        );
      }
    return (
        <>
            <div className="footer_bootem">
                <div className="footer_container">
                    <div className="sail"></div>
                    <div className="upper_footer">
                        <div className="left_footer">
                            <div className="company_logo_div">
                                {theme === 'light' ? <img className='company_logo' src={logo} alt="" /> : <img className='company_logo' src={logo_dark} alt="" />}
                            </div>

                            <div className="company_address_div">
                                <div className="address_title_div">
                                    <p className='title_address'>Let's Connect</p>
                                </div>
                                <div className="address_div">
                                    <p className="address">
                                        <span onClick={handleEmailClick}>export@kamanatraders.com</span><br />
                                        <span onClick={handlePhoneClick}>+91 7574059777</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="right_footer">
                            <div onClick={goToISO} className="iso_certified_div">
                                <img className='iso_logo' src={iso} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="lower_footer">
                        <div className="copyright_div">
                            <p>
                                © Copyright {currentYear} Kamana Traders All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
