import React,{useEffect} from 'react'
import CopperScrap from '../../product_images/copperScrap.jpg'
import CopperElectricalWiring from '../../product_images/CopperElectricalWiring.jpg'
import CopperPlumbing from '../../product_images/CopperPlumbing.jpg'
import CopperElectronics from '../../product_images/CopperElectronics.jpg'
import CopperEnergy from '../../product_images/CopperEnergy.jpg'
import CopperAutomotive from '../../product_images/CopperAutomotive.jpeg'
import CopperHeat from '../../product_images/CopperHeat.jpg'
import CopperIndustry from '../../product_images/CopperIndustry.jpg'

const Copper = () => {
    const ScrollToTopOnVisit = () => {
        useEffect(() => {
            window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        }, []); // Empty dependency array ensures this effect runs only once on mount
    
        return null; // This component doesn't render anything
    };
    ScrollToTopOnVisit()
  return (
    <>
    <div className="About_container">
                <div className="Headline_tag">
                    <p className='About_kamana'>Copper Scrap</p>
                </div>

                <div className="upper_part_product">
                    <div className="about_image_div" data-aos="fade-right">
                        <img className='product_img' src={CopperScrap} alt="" />
                    </div>
                    <div className="tagline_div product_detail_div" data-aos="fade-left">
                        <p className='tagline'>Copper scrap includes recyclable materials obtained from discarded products and manufacturing processes. It is sourced from items like wiring, plumbing, and industrial equipment, playing a crucial role in copper recycling and reducing the need for new copper mining.</p>
                    </div>
                </div>

                <div className="lower_aboutDescription" style={{marginBottom: '-11rem'}}>

                <div className="packaging_div"  style={{marginBottom: '1rem'}}>
                        <div className="feild_title_div">
                            <p className='composition_title'>Types</p>
                        </div>

                        <div className="packaging_cards_div" data-aos="zoom-in-up">

                            <div className="packaging_card">
                                <div className="packaging_card_title">Insulated Copper Wire</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Insulated copper wire scrap includes copper wire with various types of insulation, such as PVC, rubber, or enamel. This type of copper scrap requires processing to separate the copper from the insulation materials before recycling.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Copper cathodes</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Copper cathodes are high-purity copper plates used in electrical wiring, electronics, and industrial machinery. They are essential for efficient conductivity, corrosion resistance, and durability in various applications.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Millberry copper</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Millberry copper is high-quality, unalloyed copper wire scrap known for its excellent conductivity and purity, widely used in electrical and industrial applications.</div>
                            </div>

                        </div>

                    </div>


                    <div className="Application_div">
                        <div className="feild_title_div">
                            <p className="composition_title">Applications</p>
                        </div>

                        <div className="application_list_div" data-aos="zoom-in-up">
                            <div className="applications">
                                <img src={CopperElectricalWiring} className='application_image' alt="" />
                                <p className='application_title'>Electrical Wiring</p>
                                <p className='application_detail'>Used for wiring due to excellent conductivity and durability.</p>
                            </div>
                            <div className="applications">
                                <img src={CopperPlumbing} className='application_image' alt="" />
                                <p className='application_title'>Plumbing</p>
                                <p className='application_detail'>Preferred for pipes and fixtures because of its corrosion resistance.</p>
                            </div>
                            <div className="applications">
                                <img src={CopperElectronics} className='application_image' alt="" />
                                <p className='application_title'>Electronics</p>
                                <p className='application_detail'>Vital in circuit boards for high conductivity and reliability.</p>
                            </div>
                            <div className="applications">
                                <img src={CopperEnergy} className='application_image' alt="" />
                                <p className='application_title'>Renewable Energy</p>
                                <p className='application_detail'>Essential in solar panels and wind turbines for efficient energy transmission.</p>
                            </div>
                            <div className="applications">
                                <img src={CopperAutomotive} className='application_image' alt="" />
                                <p className='application_title'>Automotive</p>
                                <p className='application_detail'>Crucial in automotive wiring for reliability and performance.</p>
                            </div>
                            <div className="applications">
                                <img src={CopperHeat} className='application_image' alt="" />
                                <p className='application_title'>Heat Exchangers</p>
                                <p className='application_detail'>Utilized in heat exchangers due to excellent thermal conductivity.</p>
                            </div>
                            <div className="applications">
                                <img src={CopperIndustry} className='application_image' alt="" />
                                <p className='application_title'>Industrial Machinery</p>
                                <p className='application_detail'>Improves efficiency and longevity in machinery components.</p>
                            </div>
                        </div>

                        <div className="packaging_div">
                            <div className="feild_title_div">
                                <p className='composition_title'>Packaging</p>
                            </div>

                            <div className="packaging_cards_div" data-aos="zoom-in-up">

                                <div className="packaging_card">
                                    <div className="packaging_card_title">25KG Bags</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">For customers requiring smaller quantities of bentonite, Kamana Traders provides 25 kg bags. The bentonite is packed in high-quality polypropylene bags for added durability and protection during transportation.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Bulk Bags</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content"> Kamana Traders offers bulk bags for clients requiring large quantities of bentonite. The bulk bags can hold up to 1 ton of bentonite and can be loaded into containers for easy shipment.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Eco-Friendly Packaging</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">Kamana Traders is committed to sustainability, and we offer eco-friendly packaging options for bentonite. Customers who prefer sustainable packaging can request biodegradable or recyclable packaging materials.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Customized Packaging</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">At Kamana Traders, we understand that different clients may have their unique packaging requirements. We offer customized packaging solutions that meet our clients' specific needs and preferences.</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

    </>
  )
}

export default Copper
    