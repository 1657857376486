import React, {useEffect} from 'react'
import './FullBlog.css'
import { useNavigate } from 'react-router-dom';

const FullBlog = ({ fullBlog, setFullBlog }) => {
    const navigate = useNavigate();
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Create an array of month names
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        // Return formatted date string
        return `${day} ${monthNames[monthIndex]} ${year}`;
    };

    const formatDescription = (description) => {
        // Replace newline characters with HTML line breaks
        let formattedDescription = description.replace(/\n/g, '<br>');

        // Wrap bold text with <strong> tags
        formattedDescription = formattedDescription.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

        // Add support for other HTML tags
        formattedDescription = formattedDescription.replace(/__(.*?)__/g, '<u>$1</u>'); // Underline
        formattedDescription = formattedDescription.replace(/~(.*?)~/g, '<em>$1</em>'); // Italics
        formattedDescription = formattedDescription.replace(/<(\/?h[1-6])>/g, '<$1>'); // Headings
        formattedDescription = formattedDescription.replace(/<(\/?p)>/g, '<$1>'); // Paragraphs
        formattedDescription = formattedDescription.replace(/<(\/?ul|\/?ol|\/?li)>/g, '<$1>'); // Lists and list items
        formattedDescription = formattedDescription.replace(/<(\/?blockquote)>/g, '<$1>'); // Blockquotes
        formattedDescription = formattedDescription.replace(/<(\/?cite)>/g, '<$1>'); // Cite
        formattedDescription = formattedDescription.replace(/<(\/?code)>/g, '<$1>'); // Code
        formattedDescription = formattedDescription.replace(/<(\/?pre)>/g, '<$1>'); // Preformatted text
        formattedDescription = formattedDescription.replace(/<(\/?a)>/g, '<$1>'); // Hyperlinks
        formattedDescription = formattedDescription.replace(/<(\/?img)>/g, '<$1>'); // Images
        formattedDescription = formattedDescription.replace(/<(\/?figure)>/g, '<$1>'); // Figures
        formattedDescription = formattedDescription.replace(/<(\/?figcaption)>/g, '<$1>'); // Figcaption
        formattedDescription = formattedDescription.replace(/<(\/?div|\/?span)>/g, '<$1>'); // Div and span
        formattedDescription = formattedDescription.replace(/<(\/?sub|\/?sup)>/g, '<$1>'); // Subscript and superscript
        formattedDescription = formattedDescription.replace(/<(\/?abbr|\/?acronym)>/g, '<$1>'); // Abbreviation and acronym
        formattedDescription = formattedDescription.replace(/<(\/?del|\/?ins)>/g, '<$1>'); // Deleted and inserted text
        formattedDescription = formattedDescription.replace(/<(\/?hr)>/g, '<$1>'); // Horizontal rule

        // You can add more HTML tag replacements as needed

        return formattedDescription;
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        // Add a class to the body when the component mounts
        document.body.classList.add('no-scroll');

        // Remove the class when the component is unmounted or closed
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    return (
        <>
            <div className="modal_container">
                <div className="modal_content_div">
                    <div className="fullblog_item" >
                        <div className="fullblog_title_div">
                            <p className='cross_p' onClick={() => { setFullBlog(null) }}>&times;</p>
                            <p className='fullblog_title'>{fullBlog.title}</p>
                            <div className="kamanaTradersMark_div">
                                <div className="kamana_logo_div" onClick={()=>navigate('/')}>
                                    <img src="./profile.png" alt="" />
                                    <p>Kamana Traders</p>
                                </div>
                                <div class="dot-container">
                                    <div class="dot"></div>
                                </div>
                                <div className="date_div">
                                    <p>{formatDate(fullBlog.date)}</p>
                                </div>
                            </div>
                        </div>


                        {fullBlog.image && (
                            <div className="fullblog_image_div">
                                <img src={fullBlog.image} alt="" />
                            </div>
                        )}

                        <div className="fullblog_description_div">
                            <div dangerouslySetInnerHTML={{ __html: formatDescription(fullBlog.description) }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FullBlog
