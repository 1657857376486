import React from 'react';
import './Home.css'
import homeImage from './home.jpg'
import { useNavigate } from 'react-router-dom'
import tradingImage from './trading.png'
import shippingImage from './shipping.jpg'
import image1 from './gallary/image1.jpg'
import image2 from './gallary/image2.jpg'
import image3 from './gallary/image3.jpg'
import image4 from './gallary/image4.jpg'
import image5 from './gallary/image5.jpg'
import image6 from './gallary/image6.jpg'
import image7 from './gallary/image7.jpg'
import Questions from './Questions/Questions'
import ChooseUs from './ChooseUs/ChooseUs'

const Home = () => {
  const navigate = useNavigate();

  const gotoProducts = () => {
    navigate('/Products')
  }

  return (
    <>
      {/* {Splash && <SplashScreen />} */}
      <div className="home_container">
        <div className="home_image_div" loading='lazy' style={{ backgroundImage: `linear-gradient(rgba(9, 9, 9, 0.55), rgba(9, 9, 9, 0.6)), url(${homeImage})` }}>
          <div className="home_page">
            <h1 data-aos="fade-right">An reputable supplier of scrap materials and minerals</h1>
            <p data-aos="fade-right">We ensure high quality products and excellence customer service  </p>
            <div className="products_button_div">
              <button onClick={gotoProducts} className="produts_button type1">
                <span className="btn-txt">Explore Our Products</span>
              </button>
            </div>
          </div>
        </div>

        <ChooseUs />

        <div className="work_div">
          <div className="work_title_div">
            <h1>Discover Our Expertise</h1>
          </div>
          {/* <div className="kamana_introduction_div">
          <p>At Kamana, we specialize in trading high-quality minerals and scrap materials. With a focus on sustainability and excellence, we are dedicated to providing top-notch materials to meet our clients' diverse needs.</p>
        </div> */}
          <div className="trading_div" loading="lazy" data-aos="fade-down-right">
            <div className="trading_info">
              <h2>Minerals :</h2>
              <p>Sourcing and Supplying Premium Bentonite and China Clay Worldwide. Ensuring Consistent Quality and Reliable Service for Diverse Industries, Building Trust and Lasting Relationships with Our Valued Customers.</p>
            </div>
            <div className="trading_img">
              <img loading="lazy" src={tradingImage} alt="" />
            </div>
          </div>
          <div className="trading_div shipping_web" loading="lazy" data-aos="fade-down-left">
            <div className="trading_img">
              <img loading="lazy" src={shippingImage} alt="" />
            </div>
            <div className="trading_info">
              <h2>Scrap Materials :</h2>
              <p>Witness the power of sustainability with our innovative approach to scrap materials trading. From iron scrap, aluminium scrap, to copper scrap, we're at the forefront of transforming waste into valuable resources. Our dedication to sustainability extends beyond profit; it's about shaping a greener, more responsible future for generations to come.</p>
            </div>
          </div>
          <div className="trading_div shipping_div" loading="lazy">
            <div className="trading_info">
              <h2>Scrap Materials :</h2>
              <p>Our streamlined shipping ensures prompt delivery, fostering seamless transactions and client contentment. Trust in our logistics for swift and reliable service, enhancing your experience with every shipment.</p>
            </div>
            <div className="trading_img">
              <img loading="lazy" src={shippingImage} alt="" />
            </div>
          </div>
        </div>

        <div className="work_div">
          <div className="work_title_div">
            <h1>Kamana Trader's Trusted Showcase</h1>
          </div>
          {/* <div className="gallary_showcase">
            {gallaryImages.map((imagePath, index) => (
              <img key={index} className="gallery_img" role='presentation' decoding='async' fetchPriority='high' src={imagePath} alt={`Image ${index + 1}`} />
            ))}
          </div> */}

          <div className="carousel-container">
            <div className="carousel-track">
              <div className="carousel-item"><img className="gallery_img" src={image1} alt=" 1" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image2} alt=" 2" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image3} alt=" 3" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image4} alt=" 4" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image5} alt=" 5" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image6} alt=" 6" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image7} alt=" 7" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image1} alt=" 1" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image2} alt=" 2" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image3} alt=" 3" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image4} alt=" 4" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image5} alt=" 5" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image6} alt=" 6" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image7} alt=" 7" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image1} alt=" 1" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image2} alt=" 2" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image3} alt=" 3" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image4} alt=" 4" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image5} alt=" 5" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image6} alt=" 6" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image7} alt=" 7" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image1} alt=" 1" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image2} alt=" 2" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image3} alt=" 3" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image4} alt=" 4" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image5} alt=" 5" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image6} alt=" 6" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image7} alt=" 7" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image1} alt=" 1" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image2} alt=" 2" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image3} alt=" 3" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image4} alt=" 4" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image5} alt=" 5" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image6} alt=" 6" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
              <div className="carousel-item"><img className="gallery_img" src={image7} alt=" 7" role='presentation' decoding='async' fetchpriority='high' loading='lazy' /></div>
            </div>
          </div>
        </div>

        <Questions />
      </div>
    </>
  )
}

export default Home
