import React, { useState } from 'react'
import './Contact.css'
import contactimage1 from './contactus1.jpg'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader'

const Contact = () => {
  const [credentials, setCredentials] = useState({ firstName: '', lastName: '', companyName: '', email: '', message: '' });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      const response = await fetch('https://kamana-website-server.vercel.app/contactForm/SendMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      const json = await response.json();

      if (json.success) {
        console.log('message sent')
        toast.success('Message send successfully', {
          position: "bottom-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        console.log(json.errors)
        toast.error(json.errors, {
          position: "bottom-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      toast.error('Message send failed', {
        position: "bottom-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.log('Submit fail', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="contact_container">
        <ToastContainer />
        <div className="contact_image_title_div">
          <div className="image_title" loading='lazy' style={{ backgroundImage: `linear-gradient(rgba(9, 9, 9, 0.4), rgba(9, 9, 9, 0.6)), url(${contactimage1})` }}>
            <div className="overlay">
              <p className='p_title'>Contact Us</p>
            </div>
          </div>
        </div>

        <div className='contact_content_div'>
          <div className="paragraph_div">
            <h1>Always Here for You</h1>
            {/* <p>At Kamana Traders, we're here to help! Whether you're curious about our top-notch Bentonite and China Clay products or exploring partnership opportunities, we've got you covered.</p>
            <p>Contact us effortlessly through phone, email, or our online form. Our dedicated customer support team aims to respond within a few hours, ensuring your inquiries are addressed promptly. For urgent matters, reach out directly for immediate assistance.</p>
            <p>Your interest in Kamana Traders is valued, and we can't wait to connect with you soon!</p> */}
            <p className='pc_paragraph'>Your Gateway to Quality Bentonite and China Clay. Contact us seamlessly via phone, email, or our online form. Our swift customer support ensures timely responses. For urgent needs, reach out directly. Your interest is valued – connect with Kamana Traders today!</p>
            <p className='mobile_paragraph'>Explore premium Bentonite and China Clay at Kamana Traders. Contact us seamlessly for swift support. Your interest is highly valued!</p>
          </div>
        </div>

        <div className="location_details_div">
          <div className="location_heading_div">
            <h1>Our Base and Operations</h1>
          </div>
          <div className="location_content_div">
            <div className="map_div"  data-aos="fade-down-right">
              <iframe title='map_location' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.6396208673286!2d70.22088377600757!3d23.000275917158564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950b1b7051e1049%3A0xdde44bf8974d5993!2sKandla%20Port!5e0!3m2!1sen!2sin!4v1718460429326!5m2!1sen!2sin" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className="map_div" >
              <iframe title='map_location' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.769878016916!2d69.69948008625117!3d22.736793173767516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39572bfcd4c49b8f%3A0xac6159b9ef8cc470!2sMundra%20port!5e0!3m2!1sen!2sin!4v1718460554925!5m2!1sen!2sin" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>

        <div className="location_details_div">
          <div className="location_heading_div">
            <h1>Connect with KAMANA TRADERS</h1>
          </div>
          <div className="location_content_div">
            <div className="location_div" data-aos="fade-right">
              <div className="phone_div">
                <div className="icon_div">
                  <img src="./phone.png" loading='lazy' className='icon_image' alt="" />
                </div>
                <div className="phone_numbers">
                  <Link className='mobileNumber' to="tel:+918128806711">+91 8128806711</Link>
                  <Link className='mobileNumber' to="tel:+917574059777">+91 7574059777</Link>
                </div>
              </div>
              <div className="phone_div">
                <div className="icon_div">
                  <img src="./location.png" loading='lazy' className='icon_image' alt="" />
                </div>
                <div className="phone_numbers">
                  <Link className='mobileNumber'
                    target="_blank"
                    rel="noopener noreferrer">Plot No. 34 Greenland Society, Meghpar</Link>
                  <Link className='mobileNumber'
                    target="_blank"
                    rel="noopener noreferrer">Kumbhardi Anjar Kutch 37011</Link>
                </div>
              </div>
              <div className="phone_div">
                <div className="icon_div">
                  <img src="./mail.png" loading='lazy' className='icon_image' alt="" />
                </div>
                <div className="phone_numbers">
                  <Link className='mobileNumber' to="mailto:export@kamanatraders.com">export@kamanatraders.com</Link>
                </div>
              </div>

            </div>
            {/* <div className="map_div" data-aos="fade-left">
              <iframe title='map_location' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29355.150013134997!2d69.98922209553236!3d23.11927858486292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3950c0b4b1cbc21f%3A0xd09bb95bbeff6aba!2sAnjar%2C%20Gujarat%20370110!5e0!3m2!1sen!2sin!4v1710009422432!5m2!1sen!2sin" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div> */}
          </div>
        </div>

        {loading && <Loader />}

        <div className="contact_form_div">
          <div className="form_title_div">
            <h1>Contact Form</h1>
          </div>
          <form className="form_div" onSubmit={handleFormSubmit} data-aos="zoom-in-up">
            <div className="name_div">
              <div className="first_name">
                <input type="text" placeholder='First Name' name='firstName' onChange={handleInputChange} required />
              </div>
              <div className="last_name">
                <input type="text" placeholder='Last Name' name='lastName' onChange={handleInputChange} required />
              </div>
            </div>
            <div className="form_box">
              <input type="text" placeholder='Company Name' name='companyName' onChange={handleInputChange} required />
            </div>
            <div className="form_box">
              <input type="text" placeholder='email' name='email' onChange={handleInputChange} required />
            </div>
            <div className="form_box">
              <textarea placeholder='Message' name='message' onChange={handleInputChange} required ></textarea>
            </div>
            <div className="form_submit_div">
              <button type='submit' className='form_submit_button'>
                <div className="svg-wrapper-1">
                  <div className="svg-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="currentColor"
                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>

      </div>
    </>
  )
}

export default Contact
