import React, { useState, useEffect, useContext } from 'react';
import './App.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Components/Home/Home'
import Navbar from './Components/Navbar/Navbar'
import About from './Components/About/About'
import Blog from './Components/Blog/Blog';
import Contact from './Components/Contact/Contact';
import Products from './Components/Products/Products';
import ChinaClay from './Components/Products/Product_items/ChinaClay/ChinaClay';
import Footer from './Components/Footer/Footer';
import AdminPanelRoutes from './Components/AdminPanel/AdminPanelRoutes';
import Bentonite from './Components/Products/Product_items/Bentonite/Bentonite';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Aluminium from './Components/Products/Product_items/Aluminium/Aluminium';
import Copper from './Components/Products/Product_items/Copper/Copper';
import Iron from './Components/Products/Product_items/Iron/Iron';
import SplashScreen from './Components/SplashScreen/SplashScreen';
import ToTop from './Components/toTop/ToTop';
import { ThemeContext } from './Components/Theme/ThemeContext';

function App() {
  AOS.init({
    debounceDelay: 130,
    throttleDelay: 130,
    offset: 150,
  });

  const [Splash, setSplash] = useState(true)
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplash(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={theme}>
      {Splash && <SplashScreen />}
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Products/ChinaClay" element={<ChinaClay />} />
          <Route path="/Products/Bentonite" element={<Bentonite />} />
          <Route path="/Products/Aluminium" element={<Aluminium />} />
          <Route path="/Products/Copper" element={<Copper />} />
          <Route path="/Products/Iron" element={<Iron />} />
          <Route path="/backend/*" element={<AdminPanelRoutes />} />
        </Routes>
        <ToTop />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
