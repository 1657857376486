import React, {useEffect} from 'react'
import IronScrap from '../../product_images/IronScrap.jpg'
import IronContruction from '../../product_images/IronConstruction.jpg'
import IronAutomotive from '../../product_images/IronAutomotive.jpg'
import IronRailway from '../../product_images/IronRailway.jpg'
import IronArt from '../../product_images/IronArt.jpg'
import IronAgriculture from '../../product_images/IronAgriculture.jpg'

const Iron = () => {
    const ScrollToTopOnVisit = () => {
        useEffect(() => {
            window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        }, []); // Empty dependency array ensures this effect runs only once on mount
    
        return null; // This component doesn't render anything
    };

    ScrollToTopOnVisit()
  return (
    <>
    <div className="About_container">
                <div className="Headline_tag">
                    <p className='About_kamana'>iron Scrap</p>
                </div>

                <div className="upper_part_product">
                    <div className="about_image_div" data-aos="fade-right">
                        <img className='product_img' src={IronScrap} alt="" />
                    </div>
                    <div className="tagline_div product_detail_div" data-aos="fade-left">
                        <p className='tagline'>Iron scrap consists of recyclable iron materials derived from discarded products, manufacturing processes, and demolition projects. Common sources include old vehicles, machinery, construction debris, and appliances. Recycling iron scrap is essential for conserving natural resources, reducing energy consumption, and minimizing environmental impact.</p>
                    </div>
                </div>

                <div className="lower_aboutDescription" style={{marginBottom: '-11rem'}}>

                <div className="packaging_div"  style={{marginBottom: '1rem'}}>
                        <div className="feild_title_div">
                            <p className='composition_title'>Types</p>
                        </div>

                        <div className="packaging_cards_div" data-aos="zoom-in-up">

                            <div className="packaging_card">
                                <div className="packaging_card_title">HMS 1 & 2 (Heavy Melting Scrap)</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">HMS 1 & 2 (Heavy Melting Scrap) consists of recyclable iron and steel scrap, essential for steelmaking processes due to its quality and recyclability.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Shredded Scrap</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Shredded Scrap refers to ferrous scrap metal that has been processed through a shredder, facilitating its use in steelmaking and recycling industries for efficient melting and production.</div>
                            </div>
                            <div className="packaging_card">
                                <div className="packaging_card_title">Cast Iron Scrap</div>
                                <hr className="packaging_card_divider" />
                                <div className="packaging_card_content">Cast Iron Scrap consists of discarded items made from cast iron, widely recycled for manufacturing new cast iron products and components in industries like automotive, construction, and engineering.</div>
                            </div>

                        </div>

                    </div>


                    <div className="Application_div">
                        <div className="feild_title_div">
                            <p className="composition_title">Applications</p>
                        </div>

                        <div className="application_list_div" data-aos="zoom-in-up">
                            <div className="applications">
                                <img src={IronContruction} className='application_image' alt="" />
                                <p className='application_title'>Construction</p>
                                <p className='application_detail'> Reinforcing structures, reducing costs, and sustainable recycling practices.</p>
                            </div>
                            <div className="applications">
                                <img src={IronAutomotive} className='application_image' alt="" />
                                <p className='application_title'>Automotive Industry</p>
                                <p className='application_detail'>Manufacturing car parts, engines, and chassis components.</p>
                            </div>
                            <div className="applications">
                                <img src={IronRailway} className='application_image' alt="" />
                                <p className='application_title'>Railways</p>
                                <p className='application_detail'>Manufacturing rails, locomotives, and other railway infrastructure.</p>
                            </div>
                            <div className="applications">
                                <img src={IronArt} className='application_image' alt="" />
                                <p className='application_title'>Art and Sculpture</p>
                                <p className='application_detail'>Crafting sculptures and artistic installations.</p>
                            </div>
                            <div className="applications">
                                <img src={IronAgriculture} className='application_image' alt="" />
                                <p className='application_title'>Agricultural Machinery</p>
                                <p className='application_detail'>Manufacturing farming tools and equipment.</p>
                            </div>
                        </div>

                        <div className="packaging_div">
                            <div className="feild_title_div">
                                <p className='composition_title'>Packaging</p>
                            </div>

                            <div className="packaging_cards_div" data-aos="zoom-in-up">

                                <div className="packaging_card">
                                    <div className="packaging_card_title">25KG Bags</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">For customers requiring smaller quantities of bentonite, Kamana Traders provides 25 kg bags. The bentonite is packed in high-quality polypropylene bags for added durability and protection during transportation.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Bulk Bags</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content"> Kamana Traders offers bulk bags for clients requiring large quantities of bentonite. The bulk bags can hold up to 1 ton of bentonite and can be loaded into containers for easy shipment.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Eco-Friendly Packaging</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">Kamana Traders is committed to sustainability, and we offer eco-friendly packaging options for bentonite. Customers who prefer sustainable packaging can request biodegradable or recyclable packaging materials.</div>
                                </div>
                                <div className="packaging_card">
                                    <div className="packaging_card_title">Customized Packaging</div>
                                    <hr className="packaging_card_divider" />
                                    <div className="packaging_card_content">At Kamana Traders, we understand that different clients may have their unique packaging requirements. We offer customized packaging solutions that meet our clients' specific needs and preferences.</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

    </>
  )
}

export default Iron
