import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const AdminNav = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/backend/login');
        localStorage.removeItem('token');
        window.location.reload();
    };
    return (
        <div className="Admin_navBar">
            <p className='admin_title'>Admin Panel</p>
            <Link onClick={handleLogout} className='logout_button'>Logout</Link>
        </div>
    )
}

export default AdminNav
