import React from 'react'
import whyusimg from './image2.jpg'
import './ChooseUs.css'
import { Link } from 'react-router-dom'

const ChooseUs = () => {
    return (
        <>
            <div className="work_div" style={{marginTop: '1rem'}}>
                <div className="work_title_div">
                    <h1>Why Choose Kamana ?</h1>
                </div>
                <div className="whyus_div">
                        <div className="whyus_tagline_div">
                            <p className="tagline_whyus">
                                Unleash Possibilities, Choose Kamana Traders
                            </p>
                        </div>
                    <div className="whyus_content">
                    <div className="whyus_img_div" data-aos="fade-down-right">
                        <img src={whyusimg} alt="" />
                    </div>
                        <div className="whyus_desc">
                            <ul>
                                <li>Elevate your standards with our unparalleled quality.</li>
                                <li>Experience reliability redefined with every delivery.</li>
                                <li>Let our dedicated team pave the way for your success.</li>
                                <li>Discover affordability without compromising excellence.</li>
                                <li>Trust in transparency at every step of the process.</li>
                            </ul>
                        <div className="getinTouch">
                            <Link className='getintouch_btn' to='/Contact'>Get in Touch</Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseUs
