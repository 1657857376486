import React, { useState, useEffect, useContext } from 'react';
import './Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './logo.png'
import Theme from '../Theme/Theme';
import logo_dark from './logo_dark.png'
import { ThemeContext } from '../Theme/ThemeContext';
import dropDown from './dropDown.png'

const Navbar = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isNavbarFixed, setNavbarFixed] = useState(false);

  const handleCheckboxChange = () => {
    setCheckboxChecked(!isCheckboxChecked);
    setMenuVisible(!isMenuVisible);
  };

  const closeNavbar = () => {
    setMenuVisible(false);
    setCheckboxChecked(false)
  };

  const toHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight * 0.1;

      setNavbarFixed(scrollPosition > threshold);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <div className={`navbar_container ${isNavbarFixed ? 'navbar_fixed' : ''}`}>
        <div className="mobile_responsive">
          <div className="logo_div">
            {theme === 'light' ? <img className='logo_png' onClick={toHome} src={logo} alt="Kamana traders logo" /> : <img className='logo_png' onClick={toHome} src={logo_dark} alt="Kamana traders logo" />}
            <p className='iso_company'>An <span className='iso_span'>ISO</span> 9005:2015 Certified Company</p>
          </div>

          <div className="hamburger_div">
            <input type="checkbox" id="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
            <label htmlFor="checkbox" className="toggle">
              <div className="bars" id="bar1"></div>
              <div className="bars" id="bar2"></div>
              <div className="bars" id="bar3"></div>
            </label>
          </div>
        </div>

        <div className={`menu_group ${isMenuVisible ? 'showMenu' : ''}`}>
          <div className="menu_div_center">
            <Link className='link' onClick={closeNavbar} to='/'><span className={`box ${location.pathname === '/' ? 'active' : ''}`}>Home</span></Link>
            <Link className='link' onClick={closeNavbar} to='/About'><span className={`box ${location.pathname === '/About' ? 'active' : ''}`}>About Us</span></Link>

            <Link className='link Products_down_list_div' to='/Products'>
              <span className={`box Product_name_icon_div ${location.pathname.startsWith('/Products') ? 'active' : ''}`}>Products
                <img src={dropDown} alt="dropDown icon" className='product_dropdown_icon' />
              </span>
              <div className="product_list_display_div">
                <Link to='/Products/' className='product_list_item_name' onClick={closeNavbar}>All Products</Link>
                <Link to='/Products/ChinaClay' className='product_list_item_name' onClick={closeNavbar}>China Clay</Link>
                <Link to='/Products/Bentonite' className='product_list_item_name' onClick={closeNavbar}>Bentonite</Link>
                <Link to='/Products/Aluminium' className='product_list_item_name' onClick={closeNavbar}>Aluminium Scrap</Link>
                <Link to='/Products/Copper' className='product_list_item_name' onClick={closeNavbar}>Copper Scrap</Link>
                <Link to='/Products/Iron' className='product_list_item_name' onClick={closeNavbar}>Iron Scrap</Link>
              </div>
            </Link>

            <Link className='link' onClick={closeNavbar} to='/Blog'><span className={`box ${location.pathname === '/Blog' ? 'active' : ''}`}>Updates</span></Link>
          </div>

          <div className="menu_div_right">
            <Link className={`contactus link ${location.pathname === '/Contact' ? 'active' : ''}`} onClick={closeNavbar} to='/Contact'>Contact Us</Link>
            <Theme />
          </div>

        </div>
      </div>
    </>
  );
};

export default Navbar;
