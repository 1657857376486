import React, { useEffect } from 'react';
import './SplashScreen.css';
import logo from './logo.png'

const SplashScreen = () => {
    useEffect(() => {
        document.body.classList.add('no-scroll');

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    return (
        <div className="splash-screen">
            <img src={logo} alt="Logo" className="splash-logo" />
        </div>
    );
};

export default SplashScreen;
