import React from 'react';
import './Products.css';
import { useNavigate } from 'react-router-dom';
import ChinaClay from './product_images/chinaClay.jpg'
import Bentonite from './product_images/bentonite.jpg'
import Aluminium from './product_images/AluminiumScrap.jpg'
import Copper from './product_images/copperScrap.jpg'
import Iron from './product_images/IronScrap.jpg'

const Products = () => {
  const navigate = useNavigate();
  // const [products, setProducts] = useState([]);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     setLoading(true)
  //     try {
  //       const response = await fetch('https://kamana-traders-backend.vercel.app/Products/FetchProducts');
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch products');
  //       }
  //       const data = await response.json();
  //       setProducts(data); // Assuming data is an array of products
  //     } catch (error) {
  //       console.error('Error fetching products:', error);
  //       // Handle error state or retry logic as needed
  //     } finally {
  //       setLoading(false)
  //     }
  //   };

  //   fetchProducts();
  // }, []);

  const handleProductDetails = (product) => {
    navigate(`/Products/${product}`);
  }

  return (
    <>
      <div className="products_container">
        <div className="product_imageTitle">
          <div className="product_Image" loading='lazy'>
            <div className="product_image_text_div">
              <p className='product_image_text'>Our Products</p>
            </div>
          </div>
        </div>

        <div className="all_products_list_div">

            <div className="product_item">
              <p className='product_name'>China Clay</p>
              <img loading='lazy' src={ChinaClay} alt='China Clay' className="product_image" />
              <p className='product_shortDecription'>Discover our premium China Clay at Kamana Traders. Renowned for purity and versatility, it's perfect for ceramics and cosmetics, guaranteeing top quality.</p>
              <button className='product_explore_button' onClick={() => handleProductDetails('ChinaClay')}>Read more</button>
            </div>

            <div className="product_item">
              <p className='product_name'>Bentonite</p>
              <img loading='lazy' src={Bentonite} alt='Bentonite' className="product_image" />
              <p className='product_shortDecription'>At Kamana Traders, our Bentonite excels in purity and effectiveness. Ideal for drilling fluids and foundry casting, it ensures superior reliability.</p>
              <button className='product_explore_button' onClick={() => handleProductDetails('Bentonite')}>Read more</button>
            </div>

            <div className="product_item">
              <p className='product_name'>Aluminium Scrap</p>
              <img loading='lazy' src={Aluminium} alt='Aluminium' className="product_image" />
              <p className='product_shortDecription'>At Kamana Traders, our aluminum scrap delivers premium quality and versatility. From recycling to manufacturing, it's a reliable choice for various applications.</p>
              <button className='product_explore_button' onClick={() => handleProductDetails('Aluminium')}>Read more</button>
            </div>

            <div className="product_item">
              <p className='product_name'>Copper Scrap</p>
              <img loading='lazy' src={Copper} alt='Copper' className="product_image" />
              <p className='product_shortDecription'>At Kamana Traders, our copper scrap offers unmatched quality and value. Whether for recycling or industrial use, it's a dependable choice for your needs.</p>
              <button className='product_explore_button' onClick={() => handleProductDetails('Copper')}>Read more</button>
            </div>

            <div className="product_item">
              <p className='product_name'>Iron Scrap</p>
              <img loading='lazy' src={Iron} alt='Iron' className="product_image" />
              <p className='product_shortDecription'>At Kamana Traders, our iron scrap is a trusted choice for recycling and industrial applications, ensuring quality and sustainability.</p>
              <button className='product_explore_button' onClick={() => handleProductDetails('Iron')}>Read more</button>
            </div>

        </div>
      </div>
    </>
  );
};

export default Products;
