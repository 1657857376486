import React, { useState, useEffect } from 'react';
import AdminNav from '../AdminNav';
import Loader from '../../Loader/Loader';
import './ContactData.css';
import { Link } from 'react-router-dom';

const ContactData = () => {
    const [loading, setLoading] = useState(false);
    const [inquiries, setInquiries] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchInquiry = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://kamana-website-server.vercel.app/contactForm/ContactData');
                const data = await response.json();
                if (data.success) {
                    setInquiries(data.ContactData.reverse());
                } else {
                    console.log('something went wrong');
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInquiry();
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredInquiries = inquiries.filter((contact) =>
        contact.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.companyName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="AddBlog_container">
                {loading && <Loader />}
                <AdminNav />
                <hr className='nav_hr' />
                <div className="Delete_blog_title">
                    <p>Inquiries</p>
                </div>
                <div className='inquiries_search_list_div'>
                    <div className="searchFilterContact_div">
                        <input
                            type="text"
                            className='searchFilter'
                            placeholder='Search Contact'
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    {filteredInquiries.length > 0 ? (
                        <div className='Inquiries_list_div'>
                            {filteredInquiries.map((contact, index) => (
                                <div className="inquiry_item" key={index}>
                                    <div className="inquiry_feild">
                                        <p className='inquiry_feild_title_value'><span className='inquiry_feild_title'>Name:</span> <span className='inquiry_feild_value'> {contact.firstName} {contact.lastName}</span></p>
                                    </div>
                                    <hr className="packaging_card_divider" />
                                    <div className="inquiry_feild">
                                        <p className='inquiry_feild_title_value'><span className='inquiry_feild_title'>Company:</span> <span className='inquiry_feild_value'> {contact.companyName}</span></p>
                                    </div>
                                    <hr className="packaging_card_divider" />
                                    <div className="inquiry_feild">
                                        <p className='inquiry_feild_title_value'><span className='inquiry_feild_title'>Email:</span><span className='inquiry_feild_value'>  {contact.email}</span></p>
                                    </div>
                                    <hr className="packaging_card_divider" />
                                    <div className="inquiry_feild">
                                        <p className='inquiry_feild_title_value'><span className='inquiry_feild_title'>Message:</span><span className='inquiry_feild_value'>  {contact.message}</span></p>
                                    </div>
                                    <div className="reply_button_div">
                                        <Link to={`mailto:${contact.email}`} className='reply_btn'>Reply</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='no_inquiry'>No Inquiries</div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ContactData;
