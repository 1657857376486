import React, {useEffect} from 'react'
import BentoniteImage from '../../product_images/bentonite.jpg'
import Bentonite1 from '../../product_images/Bentonite1.png'
import BentoniteFoundry from '../../product_images/BentoniteFoundry.jpg'
import BentoniteDrilling from '../../product_images/BentoniteDrilling.jpg'
import BentoniteConstruction from '../../product_images/BentoniteConstructions.jpg'
import BentoniteAgriculture from '../../product_images/BentoniteAgriculture.jpg'
import BentoniteCosmetics from '../../product_images/ChinaClayCosmetics.jpg'

const Bentonite = () => {
  const ScrollToTopOnVisit = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []); // Empty dependency array ensures this effect runs only once on mount

    return null; // This component doesn't render anything
};
ScrollToTopOnVisit()
  return (
    <>
      <div className="About_container">
        <div className="Headline_tag">
          <p className='About_kamana'>Bentonite</p>
        </div>

        <div className="upper_part_product">
          <div className="about_image_div" data-aos="fade-right">
            <img className='product_img' src={BentoniteImage} alt="" />
          </div>
          <div className="tagline_div product_detail_div" data-aos="fade-left">
            <p className='tagline'>Bentonite is a versatile clay mineral known for its absorbent properties. It is especially valued for its exceptional binding, sealing, and swelling capabilities. Additionally, it plays a crucial role in environmental applications, such as wastewater treatment and soil stabilization, due to its ability to absorb toxins and heavy metals.</p>
          </div>
        </div>

        <div className="lower_aboutDescription">
          <div className="composition_div">
            <div className="feild_title_div">
              <p className="composition_title">Composition</p>
            </div>
            <div className="feild_details_div" data-aos="zoom-in-up">
              <div className="feild_detail_data">
                <p>Bentonite primarily contains montmorillonite, contributing to its swelling properties. It also includes silica, alumina, and water, with trace amounts of quartz, feldspar, iron, magnesium, calcium, and sodium, making it highly absorbent and versatile for various industrial uses.</p>
              </div>

              <div className="feild_detail_image_div">
                <img className='product_img' src={Bentonite1} alt="" />
              </div>
            </div>
          </div>

          <div className="Application_div">
            <div className="feild_title_div">
              <p className="composition_title">Applications</p>
            </div>

            <div className="application_list_div" data-aos="zoom-in-up">
              <div className="applications">
                <img src={BentoniteFoundry} className='application_image' alt="" />
                <p className='application_title'>Foundry</p>
                <p className='application_detail'>Enhances molding sand's binding properties.</p>
              </div>
              <div className="applications">
                <img src={BentoniteDrilling} className='application_image' alt="" />
                <p className='application_title'>Drilling</p>
                <p className='application_detail'>Used as a drilling mud to stabilize boreholes.</p>
              </div>
              <div className="applications">
                <img src={BentoniteConstruction} className='application_image' alt="" />
                <p className='application_title'>Construction</p>
                <p className='application_detail'>Improves soil stability in tunneling and excavation.</p>
              </div>
              <div className="applications">
                <img src={BentoniteAgriculture} className='application_image' alt="" />
                <p className='application_title'>Agriculture</p>
                <p className='application_detail'> Acts as a soil conditioner and pesticide carrier.</p>
              </div>
              <div className="applications">
                <img src={BentoniteCosmetics} className='application_image' alt="" />
                <p className='application_title'>Cosmetics</p>
                <p className='application_detail'>Used in skincare products for its absorbent qualities.</p>
              </div>
            </div>
            <div className="packaging_div">
              <div className="feild_title_div">
                <p className='composition_title'>Packaging</p>
              </div>

              <div className="packaging_cards_div" data-aos="zoom-in-up">

                <div className="packaging_card">
                  <div className="packaging_card_title">25KG Bags</div>
                  <hr className="packaging_card_divider" />
                  <div className="packaging_card_content">For customers requiring smaller quantities of bentonite, Kamana Traders provides 25 kg bags. The bentonite is packed in high-quality polypropylene bags for added durability and protection during transportation.</div>
                </div>
                <div className="packaging_card">
                  <div className="packaging_card_title">Bulk Bags</div>
                  <hr className="packaging_card_divider" />
                  <div className="packaging_card_content"> Kamana Traders offers bulk bags for clients requiring large quantities of bentonite. The bulk bags can hold up to 1 ton of bentonite and can be loaded into containers for easy shipment.</div>
                </div>
                <div className="packaging_card">
                  <div className="packaging_card_title">Eco-Friendly Packaging</div>
                  <hr className="packaging_card_divider" />
                  <div className="packaging_card_content">Kamana Traders is committed to sustainability, and we offer eco-friendly packaging options for bentonite. Customers who prefer sustainable packaging can request biodegradable or recyclable packaging materials.</div>
                </div>
                <div className="packaging_card">
                  <div className="packaging_card_title">Customized Packaging</div>
                  <hr className="packaging_card_divider" />
                  <div className="packaging_card_content">At Kamana Traders, we understand that different clients may have their unique packaging requirements. We offer customized packaging solutions that meet our clients' specific needs and preferences.</div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Bentonite
