import React from 'react'
import './BlogLoader.css'

const BlogLoader = () => {
    return (
        <>
            <div className="card">
                <div className="upper_loader">
                    <div className="card__skeleton img_div_loader"></div>
                </div>
                <div className="lower_loader">
                    <div className="card__skeleton card__title"></div>
                    <div className="card__skeleton card__description"></div>
                </div>
            </div>
        </>
    )
}

export default BlogLoader
