import React, { useState } from 'react'
import './Login.css'
import Loader from '../../Loader/Loader'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const LoginClick = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch('https://kamana-website-server.vercel.app/auth/LoginAdmin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });
            const json = await response.json();
            if (json.success) {
                toast.success('Login Successfull..', {
                    position: "bottom-right",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                localStorage.setItem('token', json.authtoken);
                navigate('/backend/admin');
            } else {
                console.log(json.errors)
                toast.error('Invalid Credentials', {
                    position: "bottom-right",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } catch (error) {
            toast.error('Login failed', {
                position: "bottom-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            console.log('Submit fail', error)
        }
        finally {
            setLoading(false)
        }
    };

    return (
        <>
            <div className="login_container">
                <ToastContainer />
                {loading && (<Loader />)}
                <form className="login_card" onSubmit={LoginClick}>
                    <p className="login_title">Log in</p>
                    <div className="login_inputBox">
                        <input type="email" id='email' name='email' onChange={onChange} required="required" />
                        <span className="login_user">email</span>
                    </div>

                    <div className="login_inputBox">
                        <input type="password" id='password' name='password' onChange={onChange} required="required" />
                        <span>Password</span>
                    </div>
                    <button className="login_enter" type="submit">Enter</button>
                </form>
            </div>
        </>
    )
}

export default Login
