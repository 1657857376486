import React, { useState } from 'react';
import './Questions.css';

const Questions = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "What types of materials does Kamana Traders specialize in sourcing and distributing?",
            answer: "Kamana Traders specializes in sourcing and distributing a wide range of materials, including premium copper scrap, aluminium scrap, china clay, and bentonite.",
        },
        {
            question: "What are the benefits of partnering with Kamana Traders for material supply and export solutions?",
            answer: "Partnering with Kamana Traders offers numerous benefits, including access to premium materials, reliable supply chain management, global distribution network, and exceptional customer service.",
        },
        {
            question: "Can Kamana Traders accommodate custom or bulk orders?",
            answer: "Yes, Kamana Traders can accommodate custom and bulk orders according to your specific requirements. Whether you need a unique material specification or large quantities, we're here to fulfill your needs with flexibility and efficiency.",
          },
          {
            question: "What are the shipping and delivery options provided by Kamana Traders?",
            answer: "Kamana Traders offers a variety of shipping and delivery options to meet your needs. Whether you require standard shipping, expedited delivery, or special handling, we can accommodate your requirements to ensure timely and efficient delivery of your materials. For exports, we primarily utilize the Kandla Port and Mundra Port, two of the leading ports in the region known for their efficiency and connectivity.",
          },
          {
            question: "What regions or countries does Kamana Traders serve for material distribution?",
            answer: "Kamana Traders serves clients worldwide and has a global distribution network to facilitate material distribution to various regions and countries. Our services extend across continents, ensuring that clients in Asia, Europe, North America, and beyond have access to our premium materials and reliable supply chain solutions.",
          }
    ];
    return (
        <div className="Question_container">
            <div className="work_title_div">
                <h1>Frequently Asked Questions</h1>
            </div>
            <div className="accordion" data-aos="fade-up">
                {faqData.map((faq, index) => (
                    <div className={`accordion-item ${activeIndex === index ? 'Accordian_item_active' : ''}`} onClick={() => toggleAccordion(index)} key={index}>
                        <div className="accordion-title">
                            <h3>Q) {faq.question}</h3>
                            { activeIndex === index ? (<h1>&#8722;</h1>) : (<h1>&#43;</h1>)}
                        </div>
                        {activeIndex === index && (
                            <div className="accordion-content">
                                <p>{faq.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Questions
