import React, { useState, useEffect } from 'react';
import './DeleteBlog.css'
import AdminNav from '../AdminNav'
import Loader from '../../Loader/Loader'
import trash from './trash.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteBlog = () => {
  const [loading, setLoading] = useState(false);
  const [blogTitle, setBlogTitle] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true)
      try {
        const response = await fetch('https://kamana-website-server.vercel.app/blog/fetchBlogs');

        const data = await response.json();
        if (data.success) {
          setBlogTitle(data.AllBlogs.reverse());
        } else {
          console.log('something went wrong')
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false)
      }
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (blogId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this blog?");
    if (!isConfirmed) return;

    setLoading(true)
    try {
      const response = await fetch(`https://kamana-traders-backend.vercel.app/blog/DeleteBlog/${blogId}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        const updatedBlogs = blogTitle.filter(blog => blog._id !== blogId);
        setBlogTitle(updatedBlogs);
        toast.success('Blog deleted successfully..', {
          position: "bottom-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        console.error('Failed to delete Blog');
        toast.error("Failed to delete Blog.", {
          position: "bottom-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting Blog:', error);
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className="AddBlog_container">
        {loading && <Loader />}
        <ToastContainer />
        <AdminNav />
        <hr className='nav_hr' />
        <div className="Delete_blog_title">
          <p>Delete Blog</p>
        </div>
        <div className="Blog_list_div">
          {blogTitle.map(blog => (
            <div className="blog_title_item">
              <p>{blog.title}</p>
              <img src={trash} alt="" onClick={() => handleDelete(blog._id)} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DeleteBlog
